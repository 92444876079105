export default {
  inject: ['tripDetail'],
  props: {
    tripId: {
      required: true,
      type: [Number, String]
    }
  },
  computed: {
    trip () {
      return this.tripDetail()
    },
    isUpcomingTrip () {
      return this.tripId && this.tripId.toString()?.startsWith('__')
    }
  }
}
