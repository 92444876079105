<template>
	<div class="py-4 px-4">
		<div class="card">
			<div class="card-header justify-content-between">
				<div class="col">
					<div v-if="tableData.length" class="input-group input-group-flush d-flex flex-row-reverse">
						<input v-model.trim="search" class="form-control list-search" type="search" placeholder="Search" />
						<span class="input-group-text border-0">
							<i class="fe fe-search"></i>
						</span>
					</div>
				</div>
				<div class="col-auto d-flex align-items-end px-0">
					<download-button v-if="tableData.length" :downloading="downloadingReport" label="Download Report"
						@download="downloadReport()">
					</download-button>
				</div>
			</div>
			<b-table striped hover selectable responsive show-empty :items="tableData" :fields="fields"
				:current-page="currentPage" :busy="loading" @row-clicked="showRatingDetails">
				<template #table-busy>
					<div class="d-flex flex-column justify-content-center align-items-center">
						<div class="spinner-border" role="status"></div>
						<p class="text-center mt-2"><strong>Loading...</strong></p>
					</div>
				</template>

				<template #empty>
					<p class="text-center text-secondary">No records available</p>
				</template>

				<template #cell(date)="data">
					<span>{{ data.item.date_created | date('d MMMM, yyyy') }}</span>
				</template>

				<template #cell(passenger)="data">
					<span>{{ data.item.passenger_name }}</span>
				</template>

				<template #cell(route)="data">
					<route-description :pickup="data.item.pickup" :destination="data.item.destination">
					</route-description>
				</template>

				<template #cell(rating)="data">
					<star-rating :rated-stars="parseInt(data.item.star)"></star-rating>
				</template>

				<template #cell(comment)="data">
					<span class="ellipsis">{{ data.item.comment || 'None' }}</span>
				</template>

				<template #cell(issues)="data">
					<span>{{ getIssues(data) }}</span>
				</template>

				<template #cell(attachments)="data">
					<!--          TODO: Extract reusable function or pipe for pluralization-->
					<span>{{ (data.item.attachment_urls.length > 0 ?
						(`${data.item.attachment_urls.length} attachment${data.item.attachment_urls.length > 1 ? 's' : ''}`) :
						'None') }}
					</span>
				</template>

			</b-table>
			<div class="card-footer" v-if="totalRecords">
				<div class="row align-items-center">
					<div class="col">
						<span class="text-dark font-weight-bold">{{ tablePageText }}</span>
					</div>
					<div class="col-auto">

						<b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="pageSize"></b-pagination>
					</div>
				</div>
			</div>
		</div>

		<b-modal :id="ratingModalId" @close="closeModal" no-close-on-backdrop hide-footer no-close-on-esc>
			<template #modal-title>
				Customer rating
			</template>

			<template v-if="selectedRating">
				<div class="py-2">
					<div class="detail-item">
						<label class="detail-item__label">Name</label>
						<div class="detail-item__info">{{ selectedRating.passenger_name }}</div>
					</div>

					<div class="detail-item">
						<label class="detail-item__label">Date</label>
						<div class="detail-item__info">{{ selectedRating.date_created | date('d MMMM, yyyy') }}</div>
					</div>

					<div class="detail-item">
						<label class="detail-item__label">Route</label>
						<div class="detail-item__info">
							<route-description :pickup="selectedRating.pickup" :destination="selectedRating.destination">
							</route-description>
						</div>
					</div>

					<div class="detail-item">
						<label class="detail-item__label">Rating</label>
						<div class="detail-item__info">
							<star-rating :rated-stars="parseInt(selectedRating.star)"></star-rating>
						</div>
					</div>

					<div class="detail-item">
						<label class="detail-item__label">Issues</label>
						<div class="detail-item__info">
							<div class="issue-list" v-if="selectedRating.issues && selectedRating.issues.length">
								<span class="issue" :key="index" v-for="(issue, index) in selectedRating.issues">
									{{ issue }}
								</span>
							</div>
							<p v-else>
								None
							</p>
						</div>
					</div>

					<div class="detail-item">
						<label class="detail-item__label">Attachment(s)</label>
						<div class="detail-item__info">
							<div class="d-flex flex-wrap"
								v-if="selectedRating.attachment_urls && selectedRating.attachment_urls.length">
								<div :key="index" v-for="(attachment, index) in selectedRating.attachment_urls"
									@click="downloadAttachments([attachment])" :title="`Download ${attachment}`" class="attachment-preview">
									<div class="attachment-preview__top">
										<img class="img" src="@/assets/img/illustrations/img_preview.svg">
									</div>
									<div class="attachment-preview__info">
										<p class="file-name p-0 ellipsis">{{ attachment }}</p>
										<p class="file-type">JPG</p>
									</div>
								</div>
							</div>
							<p v-else>
								None
							</p>

						</div>
					</div>

					<div class="detail-item">
						<label class="detail-item__label">Comment</label>
						<div class="detail-item__info">
							<p class="rating-comment">
								{{ selectedRating.comment || 'None' }}
							</p>
						</div>
					</div>

				</div>
			</template>

		</b-modal>
	</div>
</template>

<script>
import TripDetail from './trip-detail-mixin'
import RouteDescription from '@/components/modules/routes/RouteDescription'
import StarRating from '@/components/core/StarRating'
import DownloadButton from '@/components/core/DownloadButton'
import moment from 'moment'
import { ExportToCsv } from 'export-to-csv'
import { extractErrorMessage } from '@/utils/helpers'
import TableView from '@/mixins/tableview'

export default {
  name: 'TripRatings',
  components: { DownloadButton, StarRating, RouteDescription },
  mixins: [TripDetail, TableView],
  data() {
    return {
      pageSize: 10,
      currentPage: 1,
      totalRecords: 0,
      loading: false,
      tableData: [],
      fields: [
        {
          key: 'date',
          label: 'Date'
        },
        {
          key: 'passenger',
          label: 'Passenger Name'
        },
        {
          key: 'route',
          label: 'Pickup & Drop off'
        },
        {
          key: 'rating',
          label: 'Rating'
        },
        {
          key: 'comment',
          label: 'Comment'
        },
        {
          key: 'issues',
          label: 'Issues'
        },
        {
          key: 'attachments',
          label: 'Attachments'
        }

      ],
      search: null,
      downloadingReport: false,
      ratingModalId: 'customerTripRatingDetailsModal',
      selectedRating: null
    }
  },
  computed: {
    tablePageText() {
      // TODO: use tableView mixin when merged
      const cpSize = (this.pageSize * this.currentPage)
      if (this.currentPage === 1 && this.totalRecords <= this.pageSize) {
        return `Showing ${this.totalRecords} record${this.totalRecords > 1 ? 's' : ''}`
      } else {
        return this.totalRecords ? `Showing ${(cpSize - this.pageSize) + 1} - ${Math.min(cpSize, this.totalRecords)} of ${this.totalRecords}` : ''
      }
    }
  },
  watch: {
    currentPage() {
      this.fetchTripList()
    }
  },
  created() {
    this.fetchRatings()
  },
  methods: {
    downloadAttachments(urls) {
      for (const url of urls) {
        window.open(url)
      }
    },
    async fetchRatings() {
      if (this.loading) {
        return
      }
      this.loading = true
      try {
        const response = await this.axios.get(`/v1/ratings/trips/${this.trip.id}`, {
          page: this.currentPage,
          limit: this.pageSize
        })
        this.tableData = response.data.data
        this.totalRecords = response.data.metadata.total
      } catch (e) {
        
      } finally {
        this.loading = false
      }
    },
    showRatingDetails(data) {
      this.selectedRating = data
      this.openModal()
    },
    async downloadReport() {
      this.downloadingReport = true
      try {
        const customerRatings = await this.axios.get(`/v1/ratings/trips/${this.trip.id}`, {
          page: 1,
          limit: null
        }).then((res) => res.data?.data || [])
        const csvData = customerRatings.map((x) => {
          return {
            date_created: moment(x.date_created).format('YYYY-MM-DD'),
            passenger_name: x.passenger_name,
            star: x.star,
            comment: x.comment || 'None',
            issues: x.issues?.length ? x.issues.map((issue) => issue.name).join(', ') : 'None',
            attachment_urls: x.attachment_urls?.length ? x.attachment_urls.join(', ') : 'None',
            trip_time: x.trip_time,
            route: this.trip.route_code
          }
        })
        const csvParams = {
          filename: `Ratings for ${this.trip.tripDescription}`,
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true,
          showTitle: true,
          title: 'Driver Customer Ratings',
          useTextFile: false,
          useBom: true,
          headers: [
            'Date Created',
            'Passenger Name',
            'Rating',
            'Comment',
            'Issues',
            'Attachments',
            'Trip Time',
            'Route'
          ]
        }
        const csvExporter = new ExportToCsv(csvParams)
        csvExporter.generateCsv(csvData)
        this.$swal({
          icon: 'success',
          title: 'Ratings Report downloaded',
          text: 'Customer Ratings Report has been downloaded successfully',
          showCloseButton: true
        })
      } catch (e) {
        this.$swal({
          icon: 'error',
          title: 'An error occurred!',
          text: extractErrorMessage(e, 'Failed to download Ratings Report for this trip'),
          showCloseButton: true
        })
      } finally {
        this.downloadingReport = false
      }
    },
    getIssues(data) {
      return data.item.issues?.length ? data.item.issues.join(', ') : 'None'
    },
    openModal() {
      this.$bvModal.show(this.ratingModalId)
    },
    closeModal() {
      this.$bvModal.hide(this.ratingModalId)
      this.selectedRating = null
    }
  }
}
</script>

<style lang="scss" scoped>
@use "src/assets/scss/partials/typography";
@use "src/assets/scss/partials/sh-colors" as colors;
@use "src/assets/scss/b-table";

.ellipsis {
  // TODO: extract to common styles partial
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0.5rem 0;
  font-size: 0.875rem;
  font-weight: 500;
}

.detail-item {
  padding: 0.5rem;
  display: flex;

  &__label {
    flex-grow: 1;
    width: 8.5rem;
    color: colors.$sh-neutral-600;
    font-weight: 500;
    font-size: 0.9375rem;
    line-height: 1.5rem;
  }

  &__info {
    color: colors.$sh-primary-dark;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    width: 100%;
    padding-left: 1rem;
    display: flex;
    justify-content: flex-start;
  }
}

.attachment-preview {
  height: 150px;
  width: 140px;
  margin: 0.5rem;
  border: 1px solid #E5E6E6;
  border-radius: 4px;
  cursor: pointer;

  &__top {
    padding: 0.625rem;
    background-color: colors.$sh-neutral-100;

    .img {
      width: 4rem;
      height: 4rem;
      margin: 0 auto;
    }
  }

  &__info {
    padding: 0.625rem 0.75rem;
    background-color: white;

    .file-type {
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1.25rem;
      color: colors.$sh-neutral-200;
      margin: 0;
    }
  }
}

.rating-comment {
  background-color: colors.$sh-neutral-100;
  border-radius: 8px;
  padding: 1rem;
  min-height: 3rem;
  width: 100%;
}

.issue-list {
  display: flex;
  flex-wrap: wrap;

  .issue {
    padding: 0.1875rem 0.5rem;
    background-color: colors.$sh-primary-lighter;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
    border-radius: 100px;
    margin: 0.25rem;
  }
}
</style>
